.Page_Dashboard{
    width: 100%;
    height: auto;
    padding: 0px 0 80px;
    position: relative;

    background-color: #202223;
}
.Page_Dashboard .div_opt_menu_dash{
    background-color: #061821;
    padding: 40px 0 20px;
}
.Page_Dashboard .div_opt_menu_dash .container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    transition: 0.5s;
}

.Page_Dashboard .div_opt_menu_dash .dash_title{
    /* color: #fff; */
    font-size: 40px;
    font-family: 'Bebas Neue';
}
.Page_Dashboard .div_opt_menu_dash .dash_title .list_opt{
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.Page_Dashboard .div_opt_menu_dash .dash_title .list_opt .show_opt{
    padding: 10px;
    cursor: pointer;
    position: relative;
}
.Page_Dashboard .div_opt_menu_dash .dash_title .list_opt .show_opt_active{
    padding: 10px;
    cursor: pointer;
    position: relative;
    border-bottom: 4px solid #ffffff;
}
.Page_Dashboard .div_opt_menu_dash .dash_title .list_opt .show_opt:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 4px;
    background-color: #ffffff;
    scale: 0 1;
    transform-origin: left;
    transition: scale 0.36s;
}
.Page_Dashboard .div_opt_menu_dash .dash_title .list_opt .show_opt:hover::before{
    scale: 1;
}

.Page_Dashboard .div_opt_menu_dash .list_inf{
    background-color: #202223;
    padding: 30px 0px;
    min-height: 300px;
}

@media only screen and (max-width: 960px) {
    .Page_Dashboard .div_opt_menu_dash .dash_title .list_opt .show_opt,
    .Page_Dashboard .div_opt_menu_dash .dash_title .list_opt .show_opt_active{
        font-size: 12px;
    }
}
@media only screen and (max-width: 760px) {
    .Page_Dashboard .div_opt_menu_dash .dash_title{
        width: 100%;
    }
    .Page_Dashboard .div_opt_menu_dash .dash_title .list_opt{
        flex-direction: column;
        width: 100%;
    }
    .Page_Dashboard .div_opt_menu_dash .dash_title .list_opt .show_opt,
    .Page_Dashboard .div_opt_menu_dash .dash_title .list_opt .show_opt_active{
        width: calc(100% - 20px);
    }
}


/* slide show */
.Page_Dashboard .slideshow {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(#0a1e3f, #1a4a9f);
}
.Page_Dashboard .slideshow .each-slide-effect{
    position: relative;
}
.Page_Dashboard .slideshow .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 520px;
}
.Page_Dashboard .slideshow .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}
.Page_Dashboard .slideshow .react-slideshow-container .nav:first-of-type{
    left: 60px;
    background: #ffffff;
    fill: #061821;
}
.Page_Dashboard .slideshow .react-slideshow-container .nav:last-of-type{
    right: 60px;
    background: #ffffff;
    fill: #061821;
}

.Page_Dashboard .slideshow .container{
    gap: 30px;
}

.Page_Dashboard .slideshow .div_inf_course{
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    flex-grow: 1;
}
.Page_Dashboard .slideshow .div_inf_course .highlight_title{
    font-size: 80px;
    text-transform: uppercase;
    font-family: 'Bebas Neue';
    line-height: 80px;
    transition: 0.5s;
}
.Page_Dashboard .slideshow .div_inf_course .highlight_text{
    font-size: 14px;
    font-family: 'Inter';
    transition: 0.5s;
}

.Page_Dashboard .slideshow .div_img{    
    width: 50%;
    min-width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.5s;
}
.Page_Dashboard .slideshow .div_img .img_course{    
    width: 100%;
    max-height: 80%;
    height: fit-content;
    pointer-events: none;
}

.Page_Dashboard .slideshow .div_inf_course .highlight_button{
    margin-top: 20px;
    transition: 0.5s;
}

.Page_Dashboard .slideshow .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    text-transform: uppercase;
    margin-top: 20px;
}
.Page_Dashboard .slideshow .button .button_text {
    color: #ffffff;
    background-color: #7c9f06;
    text-align: center;
    padding: 8px 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
.Page_Dashboard .slideshow .button .button_text:hover{
    transform: scale(0.98);
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before{
    border-radius: 0 !important;
    background: #ffffff !important;
}

@media only screen and (max-width: 1200px) {
    .Page_Dashboard .slideshow .container{
        width: calc(100% - 220px);
        padding: 0px 110px;
    }
}
@media only screen and (max-width: 1050px) {
    .Page_Dashboard .slideshow .div_inf_course .highlight_title{
        width: 100%;
        font-size: 32px;
        line-height: 28px;
    }
}
@media only screen and (max-width: 1000px) {
    .Page_Dashboard .slideshow .div_inf_course .highlight_title{
        text-align: center;
    }
}
@media only screen and (max-width: 840px) {
    .Page_Dashboard .slideshow .react-slideshow-container .nav:first-of-type{
        left: 20px;
    }
    .Page_Dashboard .slideshow .react-slideshow-container .nav:last-of-type{
        right: 20px;
    }   
    .Page_Dashboard .slideshow .container{
        flex-direction: column;
        width: calc(100% - 120px);
        padding: 0px 60px;
    }
    .Page_Dashboard .slideshow .div_inf_course{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .Page_Dashboard .slideshow .div_inf_course .highlight_text{
        text-align: center;
    }
    .Page_Dashboard .slideshow .div_img{
        width: 100%;
        height: auto;
    }
    .Page_Dashboard .slideshow .div_img{
        align-items: center;
        justify-content: center;
    }
    .Page_Dashboard .slideshow .div_img .img_course{
        width: 70%;
    }
}
@media only screen and (max-width: 690px) {
    .Page_Dashboard .slideshow .each-slide-effect > div{
        height: 500px;
    }
}
@media only screen and (max-width: 480px) {
    .Page_Dashboard .slideshow .div_img .img_course{
        width: 96%;
    }
}
@media only screen and (max-width: 460px) {
}
/* end */