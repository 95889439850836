.Model_Depositions {
    width: 100%;
    height: auto;
    padding: 80px 0;
    position: relative;
    overflow: hidden;
}
.Model_Depositions .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    transition: 0.5s;
}

.Model_Depositions .title{
    padding-left: 5px;
    line-height: 60px;    
    text-align: center;    
    font-family: 'Bebas Neue', sans-serif;
    font-size: 60px;
    text-transform: uppercase;
    opacity: 0.26;
    color: #ffffff;
}

.Model_Depositions .show_img{
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
}

/* Controllers */
.Model_Depositions .show_img .gallery_prev, .Model_Depositions .show_img .gallery_next {
    position: absolute;
    top: 50%;
    z-index: 4;
    width: 50%;
    height: 100%;
    cursor: pointer;
}
.Model_Depositions .show_img .gallery_prev {
    transform: translateX(-30%) translateY(-50%) scale(.8);
    left: 0;
}
.Model_Depositions .show_img  .gallery_prev .icons_carousel_prev{
    width: -webkit-fill-available;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Model_Depositions .show_img .icons_galerry_prev{
    transform: rotate(180deg);
}

.Model_Depositions .show_img .gallery_next {
    transform: translateX(-68%) translateY(-50%) scale(.8);
    left: 100%;
}
.Model_Depositions .show_img  .gallery_next .icons_carousel_next{
    width: -webkit-fill-available;
    height: 100%;
    display: flex;
    align-items: center;
}

.Model_Depositions .show_img .icons_galerry{
    width: 42px;
    height: 42px;
    display: block;
}
/* end */

.Model_Depositions .show_img .div_carousel{
    height: 100%;
    position: absolute;
    width: 100%;
    color: #ffffff;
}
.Model_Depositions .show_img .div_carousel .div_depositions{
    width: 320px;
    min-width: 320px;
    height: 100%;

    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;

    cursor: pointer;
    position: absolute;
    text-align: center;
    transition: all 1s ease;
}
.Model_Depositions .show_img .div_carousel .div_depositions .show_data_depositions{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.Model_Depositions .show_img .div_carousel .div_depositions:nth-child(1) {
    left: 0;
    z-index: 1;
    transform: translateX(-100%) scale(.7);
    opacity: 0.6;
}
.Model_Depositions .show_img .div_carousel .div_depositions:nth-child(2) {
    left: 0;
    z-index: 2;
    transform: translateX(0%) scale(.7);
    opacity: 0.6;
}
.Model_Depositions .show_img .div_carousel .div_depositions:nth-child(3) {
    left: 50%;
    z-index: 4;
    transform: translateX(-50%) scale(1);
}
.Model_Depositions .show_img .div_carousel .div_depositions:nth-child(4) {
    left: 100%;
    z-index: 2;
    transform: translateX(-100%) scale(.7);
    opacity: 0.6;
}
.Model_Depositions .show_img .div_carousel .div_depositions:nth-child(n+5) {
    left: 100%;
    z-index: 1;
    transform: scale(.7);
    opacity: 0.6;
}
.Model_Depositions .show_img .div_carousel .div_depositions .img{
    text-align: center;
}
.Model_Depositions .show_img .div_carousel .div_depositions .img .img_profile{
    width: 149px;
    height: 149px;
    border-radius: 150px;
    pointer-events: none;
}
.Model_Depositions .show_img .div_carousel .div_depositions .name{
    font-size: 20px;
    font-weight: 600;
}
.Model_Depositions .show_img .div_carousel .div_depositions .biography{
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: italic;
    text-align: justify;
}
.Model_Depositions .show_img .div_carousel .div_depositions .area{
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}
.Model_Depositions .show_img .div_carousel .div_depositions .area .date{
    color: #895F55;
    font-size: 12px;
}

@media only screen and (max-width: 1130px) {
    .Model_Depositions .show_img .gallery_prev{
        transform: translateX(-24%) translateY(-50%) scale(.8);
    }
    .Model_Depositions .show_img .gallery_next {
        transform: translateX(-76%) translateY(-50%) scale(.8);
    }
}
@media only screen and (max-width: 980px) {
    .Model_Depositions .show_img .gallery_prev {
        transform: translateX(-28%) translateY(-50%) scale(.8);
    }
    .Model_Depositions .show_img .gallery_next {
        transform: translateX(-72%) translateY(-50%) scale(.8);
    }
    .Model_Depositions .show_img .div_carousel .div_depositions:nth-child(2){
        transform: translateX(0%) scale(.5);
    }
    .Model_Depositions .show_img .div_carousel .div_depositions:nth-child(4){
        transform: translateX(-100%) scale(.5);
    }
}
@media only screen and (max-width: 910px) {
    .Model_Depositions .container{
        flex-direction: column;
    }
    .Model_Depositions .show_img .gallery_prev{
        left: 4%;
        width: auto;
    } 
    .Model_Depositions .show_img .gallery_next{
        left: auto;
        right: 4%;
        width: auto;
        transform: translateY(-50%) scale(.8);
    }
    .Model_Depositions .show_img .div_carousel .div_depositions{
        width: 70%;
    }
    .Model_Depositions .show_img .div_carousel .div_depositions:nth-child(2){
        transform: translateX(-100%) scale(.5);
    }
    .Model_Depositions .show_img .div_carousel .div_depositions:nth-child(4){
        transform: translateX(100%) scale(.5);
    }
}

@media only screen and (max-width: 580px) {
    .Model_Depositions .show_img .gallery_prev{
        left: 0%;
        transform: translateY(-50%) scale(.8);
    } 
    .Model_Depositions .show_img .gallery_next{
        right: 0%;
        transform: translateY(-50%) scale(.8);
    }
    .Model_Depositions .show_img .div_carousel .div_depositions{
        min-width: 260px;
    }
    .Model_Depositions .show_img .div_carousel .div_depositions .name{
        font-size: 16px;
    }
    .Model_Depositions .show_img .div_carousel .div_depositions .biography{
        font-size: 10px;
    }
    .Model_Depositions .show_img .div_carousel .div_depositions .area{
        font-size: 12px;
    }
}