.Model_StudentWorks{
    width: 100%;
    height: auto;
    padding: 80px 0;
    position: relative;
    overflow: hidden;
}
.Model_StudentWorks .container{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 30px;
    transition: 0.5s;
}

.Model_StudentWorks .show_video{
    width: 60%;
    text-align: right;
    z-index: 2;
}
.Model_StudentWorks .show_video .title {
    font-family: 'Bebas Neue';
    color: #254a5e;
    font-weight: 600;
    font-size: 40px;
    text-transform: uppercase;
}
.Model_StudentWorks .background_image{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.Model_StudentWorks .background_image .img_home{
    width: 100%;
    max-width: 1440px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

@media only screen and (max-width: 910px) {
    .Model_StudentWorks .container{
        flex-direction: column;
    }
    .Model_StudentWorks .show_video{
        width: 100%;
        text-align: center;
    }
    .Model_StudentWorks .show_video .title {
        color: #ededed;
    }
    .Model_StudentWorks .background_image{
        display: none;
    }
}
@media only screen and (max-width: 580px) {
}