.Model_Payment{
    width: 100%;
    height: auto;
    padding: 80px 0px;
    overflow: hidden;
    background-color: #202223;
}
.Model_Payment .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    transition: 0.5s;
}
.Model_Payment .title{
    font-family: 'Bebas Neue';
    font-size: 50px;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
}
.Model_Payment .show_payment{
    width: 100%;
    height: 100%;
    margin-top: 30px;
}
.Model_Payment .show_payment .div_payment{
    border: none;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

.Model_Payment .list_inf_payment{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.Model_Payment .list_inf_payment .show_details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-right: 1px solid rgb(173 173 173 / 30%);
    padding-right: 30px;
    margin-right: 30px;
}
.Model_Payment .list_inf_payment .show_details .payment_title{
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: -1px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Model_Payment .list_inf_payment .show_details .payment_title .payment_show_cash{
    display: flex;
    flex-direction: row;
    gap: 4px;
    color: #ffffff;
}
.Model_Payment .list_inf_payment .show_details .payment_title .payment_show_cash .payment_sigla{
    font-size: 20px;
    height: 100%;
    line-height: 1
}
.Model_Payment .list_inf_payment .show_details .payment_title .payment_show_cash .payment_cash{
    font-family: 'Bebas Neue';
    font-size: 60px;
    line-height: 50px;
    display: flex;
    align-items: center;
}
.Model_Payment .list_inf_payment .show_details .payment_title .payment_show_cash .payment_data{
    font-size: 20px;
    display: flex;
    align-items: end;
    height: 50px;
}
.Model_Payment .list_inf_payment .show_details .payment_subtitle{
    text-align: center;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: normal;
    text-transform: uppercase;
    min-height: 30px;
}
.Model_Payment .list_inf_payment .show_details .list_category{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.Model_Payment .list_inf_payment .show_details .list_category .category_name{
    border-bottom: 1px solid rgb(173 173 173 / 30%);
    padding: 4px 0px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
}
.Model_Payment .list_inf_payment .show_details .list_category .category_name:nth-last-child(-n+1){
    border-bottom: 0px;
}
.Model_Payment .list_inf_payment .div_next{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}
.Model_Payment .list_inf_payment .div_next .show_inf{    
    background: #f5f5f5;
    -webkit-box-shadow: 0 2px 2px 0 #e5e5e5;
    box-shadow: 0 2px 2px 0 #e5e5e5;
    padding: 20px 30px;
    width: 290px;
    text-align: center;
}
.Model_Payment .list_inf_payment .div_next .show_inf .details_title{
    font-size: 18px;
    font-family: 'inter';
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}
.Model_Payment .list_inf_payment .div_next .show_inf .details_inf{
    font-size: 14px;
    margin-top: 16px;
    text-align: left;
    text-align: center;
}
.Model_Payment .list_inf_payment .div_next .show_inf .details_highlight{
    font-weight: 600;
}
.Model_Payment .list_inf_payment .div_next .show_inf .details_cancel{
    margin-top: 16px;
    font-size: 10px;
    text-align: center;
    text-align: center;
}
.Model_Payment .list_inf_payment .div_next .div_btn .button_text{
    padding: 10px 0px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #668205;
    width: -webkit-fill-available;
    cursor: pointer;
}
.Model_Payment .list_inf_payment .div_next .div_btn .button_text:hover{
    background-color: #769903;
}
.Model_Payment .list_inf_payment .div_next .div_btn .button_return{
    background-color: #b17800;
}
.Model_Payment .list_inf_payment .div_next .div_btn .button_return:hover{
    background-color: #db9704;
}

.Model_Payment .div_data_login{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
}
.Model_Payment .div_data_login .div_register{
    flex-grow: 1;
}
.Model_Payment .div_data_login .div_login{
    width: 300px;
}

@media only screen and (max-width: 810px) {
    .Model_Payment .list_inf_payment{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }
    .Model_Payment .list_inf_payment .show_details{
        width: 100%;
        border: none;
        padding-right: 0px;
        margin-right: 0px;
    }
    .Model_Payment .list_inf_payment .div_next{
        width: 100%;
    }
    .Model_Payment .list_inf_payment .div_next .show_inf{
        width: calc(100% - 60px);
    }
}
@media only screen and (max-width: 780px) {
}
@media only screen and (max-width: 660px) {
}
@media only screen and (max-width: 520px) {
}