.Model_MySignature{
    width: 100%;
    height: auto;
    padding: 60px 0 0;
    position: relative;
    overflow: hidden;
    color: #ffffff;
}
.Model_MySignature .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;
    transition: 0.5s;
    background-color: #061821;
}

.Model_MySignature .data_signature{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    transition: 0.3s;
    padding: 40px 0;
}
.Model_MySignature .data_signature .details{
    border: 1px solid #ffffff;
    padding: 14px 20px;
    position: relative;
    width: auto;
    min-width: 160px;
    text-align: center;
}
.Model_MySignature .data_signature .details .type_data{
    position: absolute;
    top: -13px;
    background: #061821;
    padding: 0px 10px;
}
.Model_MySignature .data_signature .details .type_name{
    font-family: 'Bebas Neue';
    font-size: 24px;
    line-height: 20px;
}
.Model_MySignature .data_signature .btn_alt .btn_name{
    background-color: #5D9200;
    color: #ffffff;
    padding: 10px 20px;
    cursor: pointer;
}
.Model_MySignature .data_signature .btn_alt .btn_name:hover{
    transform: scale(0.98);
}

.Model_MySignature .list_category{    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding: 40px 0;
}
.Model_MySignature .list_category .title{
    font-family: 'Bebas Neue';
    font-size: 24px;
    line-height: 20px;
}
.Model_MySignature .list_category .div_show_category{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}
.Model_MySignature .list_category .div_show_category .show_category{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.Model_MySignature .list_category .div_show_category .show_category .icon_category{
    display: flex;
}

@media only screen and (max-width: 880px) {
    .Model_MySignature .container{
        flex-direction: column;
        gap: 10px;
    }
    .Model_MySignature .data_signature,
    .Model_MySignature .list_category{
        width: 100%;
    }
    .Model_MySignature .data_signature .details{
        width: calc(100% - 40px);
    }
    .Model_MySignature .data_signature .details .type_name{
        text-align: left;
    }
    .Model_MySignature .list_category .div_show_category{
        width: 100%;
    }
    .Model_MySignature .list_category .div_show_category .show_category{
        border-bottom: 1px solid #ffffff;
        padding: 12px 0px;
        width: 100%;
        font-size: 12px;
    }
}