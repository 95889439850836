.Model_Register{
    width: calc(100% - 40px);
    height: auto;
    padding: 20px;
    color: #ffffff;
    background-color: #061821;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.Model_Register .title_page{
    font-size: 22px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
}
.Model_Register .div_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
}
.Model_Register .div_row .div_data_input{
    position: relative;
    width: fit-content;
    padding-top: 10px;
}
.Model_Register .div_row .div_data_input .input_data{
    font-family: 'inter';
    font-size: 14px;
    padding: 10px;
    outline: none;
    width: -webkit-fill-available;
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: #061821;
}
.Model_Register .div_row .div_data_input .name_input{
    position: absolute;
    left: 8px;
    top: -6px;
    padding: 0px 10px;
    background-color: #061821;
    color: #ffffff;
}

.Model_Register .div_row .div_data_input .birth_date{
    width: 156px;
}
.Model_Register .div_row .div_data_input .cpf{
    width: 110px;
    text-align: center;
}
.Model_Register .div_row .div_data_input .uf{
    width: 40px;
    text-align: center;
    text-transform: uppercase;
}

.Model_Register .div_row .checkbox{
    display: flex;
}
.Model_Register .div_row .checkbox_text{
    font-size: 12px;
}
.Model_Register .div_row .input_checkbox{
    width: 20px;
    height: 20px;
}
.Model_Register .div_row .pass_checked{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Model_Register .div_row .checked_password{
    color: #F00000;
}
.Model_Register .div_row .icons_pass{
    width: 32px;
    height: auto;
}

.Model_Register .div_button{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.Model_Register .div_button .button_login{
    margin-top: 20px;
    color: #e0e3e4;
    background-color: #37a7e5;
    width: -webkit-fill-available;
    padding: 8px 10px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    border: none;
}

.Model_Register .msg_error {
    width: auto;
    height: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FC4F81;
    background: linear-gradient(0deg, rgba(252, 79, 129, 0.2), rgba(252, 79, 129, 0.2)), #1C2023;
    margin-top: 20px;
    font-size: 12px;
}
.Model_Register .msg_none{
    display: none;
}