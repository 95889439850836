html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Poppins';
    background-color: #b9b9b9;
    overflow-x: hidden;
    overflow-y: hidden;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
p{
    padding: 0px;
    margin: 0;
}
ul {
    margin: 0px;
    padding: 0px;
}
#home {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.show_content{
    width: 100%;
    height: 100%;
    position: relative;
}
.space_topic{
    height: 60px;
    background-color: #6b6d70;
}
.show_data_page{
    width: 100%;   
}
.container{
    max-width: 1220px;
    margin: 0% auto;
    padding: 0px 20px;
}
.div_video {
    height: auto;
    width: 100%;
    aspect-ratio: 1.777;
}
.cursor_default{
    cursor: default !important;
}
.height_space_menu{
    height: 60px;
    background-color: #061821;
}

/* whatsapp fixed right */
#return{
    transition: 1s;
    position: fixed;
    bottom: 90px;
    right: 30px;
    z-index: 999;
    display: flex;
    cursor: pointer;
    background-color: #2CB742;
}
#return .icon_whatsapp{
    width: 40px;
    height: 40px;
}
.return_close {
    opacity: 0;
}
.return_index{
    opacity: 1;
}

.whatsapp a{
    display: flex;
}
.whatsapp{
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999;
}
.icon_whatsapp{
    width: 50px;
    height: 50px;
}
.space_div {
    flex-grow: 1;
}
@media only screen and (max-width: 580px) {
    #return {
        bottom: 94px;
    }
}
/* end */


/* list pages */
.space_not_data{
    padding-bottom: 40px;
}
.list_pages{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
    padding-bottom: 70px;
}
.numb_page{
    background-color: #111827;
    padding: 2px 12px;
    cursor: pointer;
}
.numb_page:hover{
    background-color: #4a566e;
}
.page_active {
    background-color: #5d9200;
}
.no_search{
    color: #111827
}
/* end */


/* input */
.input{
    border: 2px solid #ebebeb;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.span_name_input{
    position: absolute;
    left: 16px;
    transition: 0.5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}
.input:valid ~ .span_name_input, .input:focus ~ .span_name_input{
    transform: translateX(0px) translateY(-22px);
    background-color: #ffffff;
    padding: 0 10px;
    left: 6px;
}
.input:valid, .input:focus{
    border: 2px solid #EDEDED;
}
/* end */


/* svg icons with fixed size */
.icons {
    width: 24px;
    height: 24px;
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
/* end */


/* scrollbar */
::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px #111827;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px #111827;
}
/* end */

/* responsive */
@media only screen and (max-width: 580px) {
    .space_topic{
        height: 44px;
    }
}
/* end */


/* pop up */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 80%);
    font-family: 'Inter';
}
.PopUp .all {
    width: 650px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #061821;
    overflow-y: hidden;
    position: relative;

    animation-duration: 0.7s;
    animation-name: data_modal;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    z-index: 40;
    margin-bottom: 40px;
}
@keyframes data_modal {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
    position: relative;
}
.PopUp .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .div_input{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .div_contents {
    background-color: #ffffff;
    padding: 16px;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
    background: #b3b3b36e;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
.return_data_save .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
}
.return_data_save .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}
.return_data_save .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}
.return_data_save .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
}
.return_data_save .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
}
.return_data_save .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
}
.return_data_save .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
}
.return_data_save .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
}
.return_data_save .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
}
.return_data_save .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
}
.return_data_save .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
}
.return_data_save .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
}
.return_data_save .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
}
.return_data_save .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
}
.return_data_save .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
}
.return_data_save .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
}
.return_data_save .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
}
@keyframes pulse0112 {
    0%, 100% {
        transform: scale(0);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}
/* end */


/* table */
table{
    padding-bottom: 10px;
    width: 100%;
}
table th{
    font-family: "Poppins";
    font-weight: 700;
    font-size: 12px;
    color: #2D3C53;
    padding: 16px;
    
    background: #f5f5f5;
    -webkit-box-shadow: 0 2px 2px 0 #e5e5e5;
    box-shadow: 0 2px 2px 0 #e5e5e5;
}
table tr{    
    background: #f5f5f5;
    -webkit-box-shadow: 0 2px 2px 0 #e5e5e5;
    box-shadow: 0 2px 2px 0 #e5e5e5;
}
/* table tr:nth-child(2n){
    background: #F6FAFF;
} */
table td{
    padding: 0px;
    font-size: 12px;
}
/* end */


@media only screen and (max-width: 910px){ 
}