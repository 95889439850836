.Model_Details{    
    width: 100%;
    height: auto;
    padding: 40px 0 80px;
    position: relative;
    overflow: hidden;
    background-color: #202223;
}
.Model_Details .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    transition: 0.5s;
}

.Model_Details .div_title_course{ 
    width: 100%;   
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.Model_Details .div_title_course .div_img{    
    width: auto;
    height: 30px;
}
.Model_Details .div_title_course .div_img .img_course{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Model_Details .div_data_course{   
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 40px;
    min-height: 360px;
    gap: 30px;
    width: 100%;
}
.Model_Details .div_data_course .inf_course{    
    flex-grow: 1;
    padding-bottom: 10px;
    background-color: #061821;
}
.Model_Details .div_data_course .inf_course .div_video{
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 20px;
}
.Model_Details .div_data_course .inf_course .div_video .div_show_video{
    width: 100%;
    height: 100%;
}
.Model_Details .div_data_course .inf_course .div_video .div_show_video iframe{
    width: 100%;
    height: 100%;
}
.Model_Details .div_data_course .inf_course .div_text{
    padding: 0px 20px;
    margin-bottom: 20px;
}
.Model_Details .div_data_course .inf_course .div_text .text{
    font-family: 'inter';
    font-size: 16px;
    color: #ffffff;
}

.Model_Details .div_data_course .list_module{
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    overflow-y: auto;
}
.Model_Details .div_data_course .list_module .div_module{
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color:#061821;
    position: relative;
}
.Model_Details .div_data_course .list_module .div_module .show_modules{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    transition: 0.5s;
    padding: 14px 10px;
}
.Model_Details .div_data_course .list_module .div_module .div_btn{
    color: #ffffff;
    background-color: #2cb742;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}
.Model_Details .div_data_course .list_module .div_module .div_btn:hover{
    background-color: #97b203;
}
.Model_Details .div_data_course .list_module .div_module .show_modules .icon_arrow{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.Model_Details .div_data_course .list_module .div_module .show_modules .icon_arrow .arrow_rotate{
    transform: rotate(-90deg);
}
.Model_Details .div_data_course .list_module .div_module .show_modules .name_modules{
    color: #ffffff;
    font-family: 'inter';
    line-height: 16px;
    font-weight: 100;
    width: -webkit-fill-available;
}
.Model_Details .div_data_course .list_module .div_module .show_modules .qtd_classes{
    width: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 1;
    font-family: 'inter';
}
.Model_Details .div_data_course .list_module .div_module .div_list_classes{
    width: 100%;

    /* max-height: 0; */
    overflow-y: hidden;
    font-size: 14px;
    color: #3c3c3c;

    transition: max-height .2s ease-out;
    background-color: #193c4e;

    z-index: 8;
}
.Model_Details .div_data_course .list_module .div_module .div_list_classes .list_classes{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: #193B4D;
    padding: 10px;
    cursor: default;
    color: #ffffff;
    width: calc(100% - 20px);
}
.Model_Details .div_data_course .list_module .div_module .div_list_classes .list_classes:hover{
    background-color: #2ba2e2;
}

@media only screen and (max-width: 910px) {
    .Model_Details .div_data_course{
        flex-direction: column;
    }
    .Model_Details .div_data_course .list_module{
        width: 100%;
        min-width: 100%;
    }
}