.Model_TeacherRecordings{
    width: 100%;
    height: auto;
    padding: 60px 0 0;
    position: relative;
    overflow: hidden;
}
.Model_TeacherRecordings .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    transition: 0.5s;
}

.Model_TeacherRecordings .list_videos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}
.Model_TeacherRecordings .list_videos .list_div_video{
    background-color: #061821;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.Model_TeacherRecordings .list_videos .list_div_video:hover{
    transform: scale(0.98);
}
.Model_TeacherRecordings .list_videos .list_div_video .show_video{
    width: 270px;
    height: auto;
    display: flex;
}
.Model_TeacherRecordings .list_videos .list_div_video .show_video .img_video{
    width: 100%;
    height: auto;
}
.Model_TeacherRecordings .list_videos .list_div_video .title_video{
    font-size: 14px;
    color: #ffffff;
    padding: 16px;
    width: calc(100% - 32px);
}
.Model_TeacherRecordings .list_videos .list_div_video .title_data{
    padding-top: 0px;
    font-size: 10px;
}

@media only screen and (max-width: 630px) {
    .Model_TeacherRecordings .list_videos .list_div_video{
        flex-grow: 1;
    }
    .Model_TeacherRecordings .list_videos .list_div_video .show_video{
        flex-grow: 1;
        width: 100%;
    }
}