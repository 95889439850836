.Page_Login{
    width: 100%;
    height: auto;
    padding: 60px 0px;
    overflow: hidden;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    background-image: url('/public/assets/bg_login.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.Page_Login .logotipo{
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Page_Login .logotipo .svg_logotipo{
    enable-background: new 0 0 230.81 121.65;
    fill: #FFFFFF;
}
.Page_Login .logotipo .width_logo{
    width: 200px;
    height: auto;
    display: block;
}
.Page_Login .div_login{
    width: 370px;
}