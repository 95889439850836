.Page_Course{
    width: 100%;
    height: auto;
    background-color: #202223;
    min-height: 300px;
}

/* slide show */
.Page_Course .show_btn{
    /* position: absolute;
    z-index: 1;
    width: calc(100% - 80px);
    height: calc(100% - 100px);
    display: flex;
    flex-direction: row; */
}
.Page_Course .div_arrow{
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 2;
}
.Page_Course .div_arrow:nth-child(1){
    left: 0;
    justify-content: flex-end;
}
.Page_Course .div_arrow:nth-child(2){
    right: 0;
    justify-content: flex-start;
}
.Page_Course .div_arrow .icons{
    width: 40px;
    height: 40px;
}
.Page_Course .div_arrow .icons_left {
    transform: rotate(180deg);
}


.Page_Course .div_list_img{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}
.Page_Course .div_list_img .show_img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-width: 100%;
    height: 100%;
    position: absolute;
    transition: 1s;
}
.Page_Course .div_list_img .show_img .container{
    width: calc(100% - 40px);
    height: 100%;
    position: relative;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 40px;
}   
.Page_Course .div_list_img .show_img:nth-child(1){
    left: -100%;
}
.Page_Course .div_list_img .show_img:nth-child(2){
    left: 0;
}
.Page_Course .div_list_img .show_img:nth-child(n+3){
    left: 100%;
}


/* end */



/* slide show */
.Page_Course .slideshow {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(#0a1e3f, #1a4a9f);
}
.Page_Course .slideshow .each-slide-effect{
    position: relative;
}
.Page_Course .slideshow .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 520px;
}
.Page_Course .slideshow .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}
.Page_Course .slideshow .react-slideshow-container .nav:first-of-type{
    left: 60px;
    background: #ffffff;
    fill: #061821;
}
.Page_Course .slideshow .react-slideshow-container .nav:last-of-type{
    right: 60px;
    background: #ffffff;
    fill: #061821;
}

.Page_Course .slideshow .container{
    gap: 30px;
}

.Page_Course .slideshow .div_inf_course{
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    flex-grow: 1;
}
.Page_Course .slideshow .div_inf_course .highlight_title{
    font-size: 80px;
    text-transform: uppercase;
    font-family: 'Bebas Neue';
    line-height: 80px;
    transition: 0.5s;
}
.Page_Course .slideshow .div_inf_course .highlight_text{
    font-size: 14px;
    font-family: 'Inter';
    transition: 0.5s;
}

.Page_Course .slideshow .div_img{    
    width: 50%;
    min-width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.5s;
}
.Page_Course .slideshow .div_img .img_course{    
    width: 100%;
    max-height: 80%;
    height: fit-content;
    pointer-events: none;
}

.Page_Course .slideshow .div_inf_course .highlight_button{
    margin-top: 20px;
    transition: 0.5s;
}

.Page_Course .slideshow .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    text-transform: uppercase;
    margin-top: 20px;
}
.Page_Course .slideshow .button .button_text {
    color: #ffffff;
    background-color: #7c9f06;
    text-align: center;
    padding: 8px 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
.Page_Course .slideshow .button .button_text:hover{
    transform: scale(0.98);
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before{
    border-radius: 0 !important;
    background: #ffffff !important;
}
/* end */

@media only screen and (max-width: 1200px) {
    .Page_Course .slideshow .container{
        width: calc(100% - 220px);
        padding: 0px 110px;
    }
}
@media only screen and (max-width: 1050px) {
    .Page_Course .slideshow .div_inf_course .highlight_title{
        width: 100%;
        font-size: 32px;
        line-height: 28px;
    }
}
@media only screen and (max-width: 1000px) {
    .Page_Course .slideshow .div_inf_course .highlight_title{
        text-align: center;
    }
}
@media only screen and (max-width: 840px) {
    .Page_Course .slideshow .react-slideshow-container .nav:first-of-type{
        left: 20px;
    }
    .Page_Course .slideshow .react-slideshow-container .nav:last-of-type{
        right: 20px;
    }   
    .Page_Course .slideshow .container{
        flex-direction: column;
        width: calc(100% - 120px);
        padding: 0px 60px;
    }
    .Page_Course .slideshow .div_inf_course{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .Page_Course .slideshow .div_inf_course .highlight_text{
        text-align: center;
    }
    .Page_Course .slideshow .div_img{
        width: 100%;
        height: auto;
    }
    .Page_Course .slideshow .div_img{
        align-items: center;
        justify-content: center;
    }
    .Page_Course .slideshow .div_img .img_course{
        width: 70%;
    }
}
@media only screen and (max-width: 690px) {
    .Page_Course .slideshow .each-slide-effect > div{
        height: 500px;
    }
}
@media only screen and (max-width: 480px) {
    .Page_Course .slideshow .div_img .img_course{
        width: 96%;
    }
}
@media only screen and (max-width: 460px) {
}