.Model_VideoPresentation{
    width: 100%;
    height: auto;
    padding: 80px 0px;
    overflow: hidden;
}
.Model_VideoPresentation .container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    transition: 0.5s;
}
.Model_VideoPresentation .div_video{
    flex-grow: 1;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Model_VideoPresentation .div_text {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Model_VideoPresentation .div_full{
    width: 100%;
}
.Model_VideoPresentation .div_text .div_title{
    text-transform: uppercase;
    margin-bottom: 30px;
}
.Model_VideoPresentation .div_text .div_title .title {
    color: #254a5e;
    font-size: 40px;
    line-height: 34px;
    font-family: 'Bebas Neue', sans-serif;
}
.Model_VideoPresentation .div_text .subtitle{
    color: #254a5e;
    font-size: 22px;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif;
}
.Model_VideoPresentation .div_text .subtitle:nth-child(n+2) {
    margin-top: 30px;
}
.Model_VideoPresentation .div_text .text {
    font-family: 'Roboto', sans-serif;
    color: #6B6B6B;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
}

@media only screen and (max-width: 910px) {
    .Model_VideoPresentation .container{
        flex-direction: column;
    }
    .Model_VideoPresentation .div_video{
        width: 100%;
    }
    .Model_VideoPresentation .div_text{
        width: 100%;
    }
}
@media only screen and (max-width: 580px) {
    .Model_VideoPresentation .div_text .subtitle{
        font-size: 16px;
    }
    .Model_VideoPresentation .div_text .div_title{
        margin-bottom: 20px;
    }
    .Model_VideoPresentation .div_text .div_title .title{
        font-size: 24px;
        line-height: 24px;
    }
    .Model_VideoPresentation .div_text .subtitle:nth-child(n+2){
        margin-top: 20px;
    }
    .Model_VideoPresentation .div_text .text{
        font-size: 12px;
    }
}