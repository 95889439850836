.Model_ContentsExplanation{
    width: 100%;
    height: auto;
    padding: 80px 0px;
    position: relative;
    overflow: hidden;
}
.Model_ContentsExplanation .container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    transition: 0.5s;
}

.Model_ContentsExplanation .div_text{
    flex-grow: 1;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Model_ContentsExplanation .div_text .div_title{
    margin-bottom: 30px;
}
.Model_ContentsExplanation .div_text .div_title .title {
    color: #254a5e;
    font-size: 60px;
    line-height: 48px;
    font-family: 'Bebas Neue', sans-serif;
}
.Model_ContentsExplanation .div_text .subtitle {
    color: #254a5e;
    font-size: 22px;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif;
    margin-bottom: 2px;
    margin-top: 30px;
}
.Model_ContentsExplanation .div_text .text {
    font-family: 'Roboto', sans-serif;
    color: #6B6B6B;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
}
.Model_ContentsExplanation .div_text .file {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 30px;
}
.Model_ContentsExplanation .div_text .file .show_file {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.Model_ContentsExplanation .div_text .file .show_file .show_icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.Model_ContentsExplanation .div_text .file .show_file .show_icons .pdf {
    width: 64px;
    height: auto;
    display: flex;
}
.Model_ContentsExplanation .div_text .file .show_file .text_pdf {
    font-family: 'Bebas Neue';
    font-size: 40px;
    color: #FF5200;
    line-height: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
}

.Model_ContentsExplanation .list_img{
    width: 50%;
    height: 100%;
    flex-grow: 1;
    position: relative;
}
.Model_ContentsExplanation .list_img .img {
    width: 140%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 910px) {
    .Model_ContentsExplanation .container{
        flex-direction: column;
    }
    .Model_ContentsExplanation .div_text{
        width: 100%;
    }
    .Model_ContentsExplanation .list_img{
        width: 100%;
    }
    .Model_ContentsExplanation .list_img .img{
        width: 100%;
        position: relative;
    }
    .Model_ContentsExplanation .div_text .div_title .title{
        font-size: 40px;
        line-height: 38px;
    }
    .Model_ContentsExplanation .div_text .subtitle{
        margin-top: 20px;
    }
}
@media only screen and (max-width: 580px) {
    .Model_ContentsExplanation .div_text .div_title{
        margin-bottom: 20px;
    }
    .Model_ContentsExplanation .div_text .div_title .title {
        font-size: 24px;
        line-height: 24px;
    }
    .Model_ContentsExplanation .div_text .text{
        font-size: 12px;
    }
    .Model_ContentsExplanation .div_text .subtitle{
        font-size: 16px;
    }
    .Model_ContentsExplanation .div_text .file{
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        width: 100%;
    }
    .Model_ContentsExplanation .div_text .file .show_file .show_icons .pdf{
        width: 42px;
    }
    .Model_ContentsExplanation .div_text .file .show_file .text_pdf{
        font-size: 24px;
        line-height: 20px;
    }
}