.Footer{
    width: 100%;
    height: auto;
    padding: 30px 0px 0px;
    background-color: #061821;
    color: #ffffff;
    font-size: 12px;
}
.Footer .footer_div_logotipo{
    margin-bottom: 20px;
}
.Footer .footer_div_logotipo .footer_logotipo{
    width: 100px;
}
.Footer .div_menu_site{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 30px;
}
.Footer .list_social_network{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 10px;
}
.Footer .list_social_network .div_list_rede_social{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.Footer .div_middle{
    padding: 20px 0px;
}
.Footer .div_middle_title_right{
    text-align: right;
}
.Footer .div_end{
    color: #7D7D7D;
    border-top: 1px solid #616161;
    padding: 20px 0px;
}
.Footer .div_end_flex{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.Footer .div_left{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 20px;
    justify-content: center;
}
.Footer .bg_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.Footer .bg_icon .icons_footer{
    width: 40px;
    height: 40px;
    z-index: 1;
}
.Footer .bg_icon .bg_white {
    background-color: #ffffff;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    position: absolute;
    left: 1px;
    top: 1px;
}

.Footer .footer_payment{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}
.Footer .footer_payment .icons_payment{
    width: 130px;
}
.Footer .footer_payment .div_list_icons{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}
.Footer .footer_payment .icons_type_payment{
    height: 30px;
}

@media only screen and (max-width: 750px) {
    .Footer .div_left, .Footer .div_right{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .Footer .div_menu_site{
        justify-content: space-between;
        align-items: flex-start;
    }
    .Footer .div_menu_site .text_left{
        text-align: left;
    }
    .Footer .div_menu_site .text_right{
        text-align: right;
    }
    .Footer .footer_div_logotipo{
        margin: 0;
    }
    .Footer .div_end_flex{
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;
    }
    .Footer .list_social_network{
        align-items: flex-start;
        justify-content:flex-start;
    }
    .Footer .div_middle_title_right{
        text-align: center;
    }
    .Footer .img_footer{
        width: 100%;
    }
    .Footer .div_end{
        margin-top: 40px;
    }
}
@media only screen and (max-width: 580px) {
    .Footer{
        position: relative;
    }
}