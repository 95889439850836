.Model_Profile{
    width: 100%;
    height: auto;
    padding: 60px 0 0;
    position: relative;
    overflow: hidden;
}
.Model_Profile .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    transition: 0.5s;
    background-color: #061821;
}

.Model_Profile .login_div_img{
    width: 160px;
    height: auto;
    padding: 20px 0px;
}
.Model_Profile .login_div_img .login_img{
    width: 160px;
    height: auto;
    max-height: 202px;
    overflow: hidden;
    text-align: center;
}
.Model_Profile .login_div_img .login_show_img{
    width: auto;
    max-width: 160px;
    height: auto;
    max-height: 202px;
}
.Model_Profile .login_new_img{
    margin-top: 10px;
    background-color: #0083c7;
    color: #FFFFFF;
    text-align: center;
    padding: 4px 10px;
    cursor: pointer;
}
.Model_Profile .login_new_img label{
    cursor: pointer;
}
.Model_Profile .login_new_img input[type="file"]{
    display: none;
}

.Model_Profile .list_opt_login{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
}
.Model_Profile .list_opt_login .login_data_login{
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    width: -webkit-fill-available;
}
.Model_Profile .list_opt_login .login_data_login .dash_div_input{
    position: relative;
    margin-top: 12px;
}
.Model_Profile .list_opt_login .login_data_login .dash_div_input .name_input{
    position: absolute;
    left: 8px;
    top: -16px;
    padding: 0px 10px;
    background-color: #061821;
    color: #ffffff;
}
.Model_Profile .list_opt_login .login_data_login .dash_div_input .dash_input{    
    font-family: 'inter';
    font-size: 14px;
    padding: 10px;
    outline: none;
    width: -webkit-fill-available;
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: #061821;
}
.Model_Profile .list_opt_login .login_data_login .dash_div_input .birth_date{
    width: 178px;
}
.Model_Profile .list_opt_login .login_data_login .dash_div_input .input_login{
    width: 100px;
}
.Model_Profile .list_opt_login .login_data_login .dash_div_input .cpf{
    width: 110px;
    text-align: center;
}
.Model_Profile .list_opt_login .login_data_login .dash_div_input .phone{
    width: 200px;
}
.Model_Profile .list_opt_login .login_data_login .dash_div_input .uf{
    width: 40px;
    text-align: center;
    text-transform: uppercase;
}
.Model_Profile .list_opt_login .div_register_data{
    display: flex;
    align-items: flex-end;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}
.Model_Profile .list_opt_login .div_register_data .div_input{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Model_Profile .list_opt_login .div_register_data .register_data{
    margin-top: 10px;
    background-color: #799a06;
    color: #FFFFFF;
    text-align: center;
    padding: 4px 10px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Poppins';
}

.Model_Profile .list_opt_login .space_div{
    flex-grow: 1;
}

@media only screen and (max-width: 700px) {
    .Model_Profile .container{
        flex-direction: column;
        gap: 0;
    }
    .Model_Profile .login_div_img{
        width: 100%;
        order: 2;
    }
    .Model_Profile .list_opt_login{
        order: 1;
    }
    .Model_Profile .login_div_img .login_img{
        width: 50%;
        margin: 0 auto;
        height: 100%;
    }
    .Model_Profile .login_div_img .login_show_img{
        width: auto;
        height: 100%;
        max-width: fit-content;
    }
}
@media only screen and (max-width: 660px) {
    .Model_Profile .login_div_img{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Model_Profile .login_new_img{
        width: calc(100% - 20px);
    }
    .Model_Profile .dash_width_input{
        flex-grow: 1;
        width: 100%;
    }
    .Model_Profile .dash_width_input .alt_width_input{
        width: -webkit-fill-available;
    }
}
@media only screen and (max-width: 520px) {
    .Model_Profile input{
        width: -webkit-fill-available !important;
    }
    .Model_Profile .list_opt_login .login_data_login .dash_div_input{
        flex-grow: 1;
    }
}
@media only screen and (max-width: 400px) {
    .Model_Profile .login_div_img{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        width: fit-content;
        height: auto;
        margin-bottom: 10px;
    }
    .Model_Profile .login_div_img .login_img{
        width: fit-content;
        height: auto;
        text-align: center
    }
    .Model_Profile .login_div_img .login_show_img{
        width: 80%;
        height: auto;
    }
    .Model_Profile .login_new_img{
        width: -webkit-fill-available;
        flex-grow: 1;
    }
}