.PopUp .payment_voucher {
    width: 400px;
    margin: 10% auto;
    color: #ffffff;
}
.PopUp .payment_voucher .div_title{
    border-bottom: 1px solid #c9c9c9;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.PopUp .payment_voucher .div_input{
    margin-top: 20px;
}
.PopUp .payment_voucher .div_input .dash_div_input{
    position: relative;
    margin-top: 12px;
}
.PopUp .payment_voucher .div_input .dash_div_input input{
    font-family: 'inter';
    font-size: 14px;
    padding: 10px;
    outline: none;
    width: -webkit-fill-available;
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: #061821;
}
.PopUp .payment_voucher .div_input .dash_div_input .name_input{
    position: absolute;
    left: 8px;
    top: -16px;
    padding: 0px 10px;
    background-color: #061821;
    color: #ffffff;
}
.PopUp .payment_voucher .button_popup {
    width: -webkit-fill-available;
    padding: 0px;
    margin-top: 20px;
}
.PopUp .payment_voucher .button_popup .btn_save{
    font-size: 16px;
}
.PopUp .payment_voucher .button_save_popup {
    background-color: #76c57e;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: -webkit-fill-available;
    padding: 8px 0px;
}

.PopUp .payment_voucher .div_input label input[type="file"] {
    display: none;
}
.PopUp .payment_voucher .div_input label{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 41px;
    padding-top: 11px;
}
.PopUp .payment_voucher .div_input .div_remuve{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    padding-top: 11px;
}
.PopUp .payment_voucher .div_input label .div_file{
    background-color: #0083c7;
    height: 100%;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.PopUp .payment_voucher .show_msg{
    padding-top: 12px;
}
.PopUp .payment_voucher .div_button{
    width: 100%;
}