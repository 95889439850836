.Model_TeacherProfile{
    width: 100%;
    height: auto;
    padding: 80px 0;
    position: relative;
    
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    overflow: hidden;
    background: no-repeat;
    background-size: auto;
    background-position: center;
}
.Model_TeacherProfile .container{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 30px;
    transition: 0.5s;
}

.Model_TeacherProfile .show_profile{  
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 30px;  
    margin: 0% auto;
    z-index: 2;
    transition: 0.5s;
}
.Model_TeacherProfile .show_profile .show_data{
    width: 50%;  
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;  
    gap: 20px;
}
.Model_TeacherProfile .show_profile .show_data .name_profile{
    display: flex;
    align-items: center;
    gap: 20px;
}
.Model_TeacherProfile .show_profile .show_data .name_profile .img_profile{
    width: 110px;
    height: 110px;
    border-radius: 110px;
}
.Model_TeacherProfile .show_profile .show_data .name_profile .div_name{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 60px;
    text-transform: uppercase;
    color: #ffffff;
}
.Model_TeacherProfile .show_profile .show_data .name_profile .div_name .type_name{
    opacity: 0.26;
}
.Model_TeacherProfile .show_profile .show_data .text{
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #FFF;
    line-height: 26px;
}
.Model_TeacherProfile .show_profile .show_data .portfolio{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
}
.Model_TeacherProfile .show_profile .show_data .portfolio .show_a_icon{
    display: flex;
}
.Model_TeacherProfile .show_profile .show_data .portfolio .show_a_icon .icons_{
    width: 46px;
    height: 46px;
}

.Model_TeacherProfile .show_profile .file{
    width: 50%;
    position: relative;
}
.Model_TeacherProfile .show_profile .file .img_bg{
    width: 100%;
}


.Model_TeacherProfile .background_img{
    width: 100%;
    max-width: 1440px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Model_TeacherProfile .background_img .show_img{
    width: auto;
    height: 100%;
    position: absolute;
}

@media only screen and (max-width: 1190px) {
}
@media only screen and (max-width: 1030px) {
    .Model_TeacherProfile .show_profile{
        flex-direction: column;
        align-items: center;
    }
    .Model_TeacherProfile .file {
        order: 1;
    }
    .Model_TeacherProfile .show_profile .show_data{
        width: 100%;
    }
    .Model_TeacherProfile .show_profile .file{
        width: 100%;
        text-align: center;
    }
    .Model_TeacherProfile .show_profile .file .img_bg{
        width: 70%;
    }
    .Model_TeacherProfile .show_profile .show_data .portfolio .show_a_icon .icons_{
        width: 40px;
        height: 40px;
    }
}
@media only screen and (max-width: 580px) {
    .Model_TeacherProfile .show_profile .show_data .name_profile .img_profile{
        width: 60px;
        height: 60px;
    }
    .Model_TeacherProfile .show_profile .show_data .name_profile .div_name{
        font-size: 46px;
    }
    .Model_TeacherProfile .show_profile .show_data .portfolio .icons_{
        width: 32px;
        height: 32px;
    }
    .Model_TeacherProfile .background_img{
        display: none;
    }
}