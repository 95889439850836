
.Menu {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 60px;
    background: rgb(0 0 0 / 50%);
    z-index: 9;
}
.Menu .container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.Menu .div_logo{
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}
.Menu .div_logo .logotipo{
    height: 30px;
    width: auto;
    cursor: pointer;
    transition: 0.5s;
}

.Menu .List_menu{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: -webkit-fill-available;
    position: relative;
}
.Menu .List_menu .show_opt_menu{
    text-align: center;
    color: #999999;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    color: #ffffff;
}
.Menu .List_menu .show_opt_menu:hover, .Menu .List_menu .menu_active{
    background-color: #324d6b;
    border-bottom: 5px solid #37a7e5;
}

.Menu .buttons__burger {
    --size: 2rem;
    --clr: #ffffff;
    width: var(--size);
    height: calc(0.7 * var(--size));
    cursor: pointer;
    position: relative;
    display: none;
    z-index: 6;
    transition: 1s;
}
.Menu .buttons__burger #burger {
    display: none;
    width: 100%;
    height: 100%;
}
.Menu .buttons__burger span {
    display: block;
    position: absolute;
    width: 88%;
    border-radius: 0.5rem;
    border: 2px solid var(--clr);
    background-color: var(--clr);
    transition: 0.15s ease-in-out;
}
.Menu .buttons__burger span:nth-of-type(1) {
    top: 0;
    right: 0;
    transform-origin: right center;
}
.Menu .buttons__burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
}
.Menu .buttons__burger span:nth-of-type(3) {
    top: 100%;
    right: 0;
    transform-origin: right center;
    transform: translateY(-100%);
}
.Menu .buttons__burger #burger:checked~span:nth-of-type(1) {
    transform: translateY(-30%) rotate(40deg);
    width: 50%;
    top: 50%;
}
.Menu .buttons__burger #burger:checked~span:nth-of-type(3) {
    transform: translateY(-70%) rotate(-40deg);
    width: 50%;
    top: 50%;
}

.Menu .List_menu .data_user{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.Menu .List_menu .data_user .img_user{
    width: 30px;
    height: 30px;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Menu .List_menu .data_user .icon_menu_profile{
    width: 30px;
    height: 30px;
    position: relative;
    top: 4px;
}
.Menu .List_menu .data_user .show_data_login{
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    line-height: 10px;
}
.Menu .List_menu .data_user .show_data_login .type_access{
    font-size: 10px;
    line-height: 8px;
    font-family: 'Inter';
    color: #b1b1b1;
    text-transform: uppercase;
}

@media only screen and (max-width: 910px) {
    .Menu .buttons__burger {
        display: block;
    }    
    .Menu .List_menu{
        flex-direction: column;
        justify-content: center;
        gap: 30px;

        position: fixed;
        height: 100%;
        right: -170px;
        top: 0;
        z-index: 4;        
    }    
    .Menu .open_menu {
        gap: 10px;
        width: 0;
        right: -134px;
        
        animation: open_menu 0.5s;
        animation-fill-mode: forwards;

        background-color: rgb(0 0 0 / 90%);
        color: #ffffff;
        white-space: nowrap;
    }
    @keyframes open_menu {
        0%{
            right: -134px;
        }
        100%{
            width: 100%;
            right: 0;
        }
    } 
    .Menu .close_menu {
        gap: 10px;
        width: 0;
        right: 0px;
        
        animation: close_menu 0.5s;
        animation-fill-mode: forwards;
    }
    @keyframes close_menu {
        0%{
            width: 100%;
            right: 0;
        }
        100%{
            right: -134px;
            width: 0;
        }
    }
    .Menu .open_menu .show_opt_menu{
        font-size: 16px;
        height: auto;
        width: calc(100% - 40px);
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (max-width: 580px) {
    .Menu .div_logo .logotipo{
        height: 30px;
    }
}