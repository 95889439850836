.Model_Course{
    width: 100%;
    height: auto;
    padding: 40px 0 80px;
    position: relative;
    overflow: hidden;
}
.Model_Course .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    transition: 0.5s;
}

.Model_Course .div_search{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.Model_Course .div_search .type_list{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #193c4e;
}
.Model_Course .div_search .type_list .show_type_icon{
    width: 38px;
    height: 38px;
    cursor: pointer;
    transition: 0.5s;
}
.Model_Course .div_search .search{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Model_Course .div_search .search .icons_search{
    width: 24px;
    height: 24px;
    display: block;
    cursor: default;
    position: absolute;
    left: 8px;
}
.Model_Course .div_search .search .input_search{
    background-color: #193C4E;
    border: none;
    color: #FFFFFF;
    padding: 10px 10px 10px 38px;
    width: 200px;
    font-size: 16px;
    outline: none;
}
.Model_Course .div_search .search .input_search:hover{
    border: none;
    background-color: #193C4E;
}
.Model_Course .div_search .div_select{
    margin-left: 10px;
    position: relative;
}
.Model_Course .div_search .div_select .select_category{
    background-color: #193C4E;
    color: #FFFFFF;
    border: none;
    padding: 7px 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 282px;
}
.Model_Course .div_search .div_select .select_category:hover{
    border: none;
    background-color: #193C4E;
}
.Model_Course .div_search .div_select .select_category .show_icon{
    display: flex;
}
.Model_Course .div_search .div_select .select_category .show_icon .icons_arrow{
    width: 16px;
    height: 16px;
    transition: 0.5s;
}
.Model_Course .div_search .div_select .select_category .show_icon .open_icon{
    transform: rotate(90deg);
}
.Model_Course .div_search .div_select .list_category{
    width: 100%;
    position: absolute;
    top: 48px;

    max-height: 0;
    overflow-y: hidden;
    font-size: 14px;
    color: #3c3c3c;

    transition: max-height .2s ease-out;
    background-color: #193c4e;

    z-index: 8;
}
.Model_Course .div_search .div_select .list_category .show_name_category{
    padding: 10px;
    cursor: pointer;
    color: #ffffff;
    border-bottom: 1px solid #082432;
}
.Model_Course .div_search .div_select .list_category .show_name_category:hover{
    background-color: #082432;
}
.Model_Course .div_search .div_select .list_category .show_name_category:nth-last-child(-n+1){
    border-bottom: none;
}

.Model_Course .div_list_courses{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    transition: 0.5s;
}
.Model_Course .div_list_courses .div_data_course{
    width: 280px;
    cursor: pointer;
    font-size: 12px;
    background-color: #061821;
}
.Model_Course .div_list_courses .div_data_course .courses_img{
    width: 100%;
    height: 160px;
    position: relative;
    transition: 0.5s;
    overflow: hidden;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Model_Course .div_list_courses .div_data_course .courses_img .img_{
    width: 104%;
    height: auto;
    min-height: 160px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}
.Model_Course .div_list_courses .div_data_course .div_text{
    color: #0083c7;
    height: 64px;
    max-height: 64px;
    min-height: 64px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: 0.5s;
}
.Model_Course .div_list_courses .div_data_course .div_text .text{
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    transition: 0.5s;
    overflow: hidden;

    text-overflow: ellipsis;
}
.Model_Course .list_square .div_data_course .div_text .text{
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    transition: 0.5s;
    overflow: hidden;

    text-overflow: ellipsis;
    text-align: justify;

    height: 36px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Model_Course .list_line{
    flex-direction: column;
    width: 100%;
}
.Model_Course .list_line .div_data_course{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.Model_Course .list_line .div_data_course .courses_img{
    width: 270px;
    min-width: 270px;
}
.Model_Course .list_line .div_data_course .div_text{
    height: 130px;
    min-height: 130px;
    max-height: 130px;
}

.Model_Course .list_line .div_data_course .div_text .text{
    height: 96px;

    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 985px) {
    .Model_Course .list_square .div_data_course{
        flex-grow: 1;
        max-width: 48%;
    }
}
@media only screen and (max-width: 680px) {
    .Model_Course .div_search{
        flex-direction: column;
        gap: 14px;
    }
    .Model_Course .div_search .type_list{
        display: none;
    }
    .Model_Course .div_search .search{
        width: 100%;
    }
    .Model_Course .div_search .search .input_search{
        width: calc(100% - 48px);        
    }
    .Model_Course .div_search .div_select{
        width: 100%;
        margin-left: 0;
    }
    .Model_Course .div_search .div_select .select_category{
        width: calc(100% - 20px);
    }
    .Model_Course .list_square .div_data_course{
        flex-grow: 1;
    }
}
@media only screen and (max-width: 634px) {
    .Model_Course .list_square .div_data_course{
        max-width: 100%;
    }
}