.Page_HowWorks{
    width: 100%;
    height: auto;
    padding: 60px 0px;
    background-color: #ececec;
}
.Page_HowWorks .container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.Page_HowWorks .title{
    color: #000;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 50px;
    text-align: center;
    margin-top: 30px;
}
.Page_HowWorks .text{
    font-family: 'Open Sans', sans-serif;
    max-width: 800px;
    margin: 0% auto;
    margin-top: 20px;
    font-size: 14px;
    color: #3c3c3c;
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 40px;
}
.Page_HowWorks .line_color{
    width: 40%;
    height: 2px;
    margin: auto;
    background-color: #2A6E94;
}

.Page_HowWorks .list_doubts{
    max-width: 800px;
    margin: 0% auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.Page_HowWorks .list_doubts .doubts{
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    background-color: #2A6E94;
    padding: 10px;
    flex-wrap: wrap;
    gap: 10px;
    cursor: pointer;
}
.Page_HowWorks .list_doubts .triagle{
    display: flex;
}
.Page_HowWorks .list_doubts .icons_{
    width: 24px;
    height: 24px;
    transition: 1s;
    transform: rotate(90deg);
}
.Page_HowWorks .list_doubts .icons_active{
    width: 24px;
    height: 24px;
    transition: 0.5s;
    transform: rotate(180deg);
}
.Page_HowWorks .list_doubts .response{
    max-height: 0;
    overflow-y: hidden;
    font-size: 14px;
    color: #3c3c3c;

    transition: max-height .5s ease-out;
}