.Model_Home{
    width: 100%;
    height: -webkit-fill-available;
    position: relative;
    height: 560px;
    color: #FFF;
    overflow: hidden;
}
.Model_Home .background_image{
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Model_Home .img_home{
    width: 100%;
    height: -webkit-fill-available;
    margin-bottom: -8px;
}
.Model_Home .show_text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 2;
}
.Model_Home .title{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 30px;
    line-height: 30px;
    color: #FFF;
    text-shadow: 0 0 15px #000;
    transition: 0.2s ease-in-out;
    text-transform: uppercase;
    text-align: center;
}
.Model_Home .div_highlight{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.Model_Home .text{
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
}
.Model_Home .highlight{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 60px;
    line-height: 50px;
    color: #FFF;
    text-shadow: 0 0 15px #000;
    transition: 0.2s ease-in-out;
    text-transform: uppercase;
}
.Model_Home .list_text{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.Model_Home .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-transform: uppercase;
}
.Model_Home .button_text {
    color: #ffffff;
    background-color: #7c9f06;
    width: 130px;
    text-align: center;
    padding: 6px 10px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
}
.Model_Home .button_text:hover{
    transform: scale(0.96);
}

@media only screen and (max-width: 1040px) {
}
@media only screen and (max-width: 760px) {
    .Model_Home .title{
        font-size: 22px;
        line-height: 18px;
    }
    .Model_Home .highlight{
        font-size: 40px;
        line-height: 38px;
    }
    .Model_Home .text{
        font-size: 16px;
        line-height: 18px;
    }
    .Model_Home .button_text{
        padding: 4px;
        line-height: 20px;
    }
    .Model_Home .show_text{
        gap: 16px;
    }
}

@media only screen and (max-width: 640px) {
    .Model_Home .list_text{
        gap: 10px;
        margin: 0px 20px;
    }
}

@media only screen and (max-width: 600px) {
    .Model_Home .img_home{
        width: 170%;
    }
}

@media only screen and (max-width: 476px) {
    .Model_Home .highlight {
        line-height: 40px;
    }
    .Model_Home .list_text {
        flex-direction: column;
        gap: 14px;
    }
    .Model_Home .img_home{
        width: 300%;
    }
}