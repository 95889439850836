.Model_PersonalizedService{
    width: 100%;
    height: auto;
    padding: 80px 0;
    position: relative;
    overflow: hidden;
}
.Model_PersonalizedService .container{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 30px;
    transition: 0.5s;
}

.Model_PersonalizedService .div_text{
    width: 60%;
    text-align: left;
    z-index: 2;
    color: #FFF;
    font-family: 'Bebas Neue', sans-serif
}
.Model_PersonalizedService .div_text .title{
    text-transform: uppercase;
    font-size: 60px;
    line-height: 48px;
}
.Model_PersonalizedService .div_text .subtitle{
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 2px;
    margin-top: 30px;
}
.Model_PersonalizedService .div_text .text{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
}

.Model_PersonalizedService .background_image{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.Model_PersonalizedService .background_image .img_home{
    width: 100%;
    max-width: 1440px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

@media only screen and (max-width: 910px) {
    .Model_PersonalizedService .container{
        flex-direction: column;
    }
    .Model_PersonalizedService .div_text{
        width: 100%;
    }
    .Model_PersonalizedService .div_text .title {
        font-size: 40px;
        line-height: 38px;
    }
    .Model_PersonalizedService .div_text .subtitle{
        margin-top: 20px;
    }
    .Model_PersonalizedService .background_image{
        display: none;
    }
}
@media only screen and (max-width: 580px) {
    .Model_PersonalizedService .div_text .title {
        font-size: 24px;
        line-height: 24px;
    }
    .Model_PersonalizedService .div_text .subtitle{
        font-size: 16px;
    }
    .Model_PersonalizedService .div_text .text {
        font-size: 12px;
    }
}