.Model_CourseDashDetails{    
    width: 100%;
    height: auto;
    padding: 40px 0 80px;
    position: relative;
    overflow: hidden;
    background-color: #202223;
    color: #ffffff;
}
.Model_CourseDashDetails .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    transition: 0.5s;
}


/* title */
.Model_CourseDashDetails .div_title_course{ 
    width: 100%;   
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 16px;
}
.Model_CourseDashDetails .div_title_course .list_opt_topic{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.Model_CourseDashDetails .div_title_course .list_opt_topic_course{
    cursor: pointer;
}
.Model_CourseDashDetails .div_title_course .list_opt_topic .div_img{    
    width: auto;
    height: 30px;
}
.Model_CourseDashDetails .div_title_course .list_opt_topic .div_img .img_course{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Model_CourseDashDetails .div_title_course .list_opt_topic .div_title{
    font-family: 'Bebas Neue';
    font-size: 32px;
    line-height: 30px;
    position: relative;
    top: 3px;
}
.Model_CourseDashDetails .div_title_course .list_opt_topic .icon_arrow{
    width: 16px;
    height: 16px;
}
/* end */


/* show course */
.Model_CourseDashDetails .div_data_course{   
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 40px;
    min-height: 360px;
    gap: 30px;
}
.Model_CourseDashDetails .div_data_course .inf_course{    
    flex-grow: 1;
    padding-bottom: 10px;
    background-color: #061821;
    min-height: 300px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_video{
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 20px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_video .div_show_video{
    width: 100%;
    height: 100%;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_video .div_show_video iframe{
    width: 100%;
    height: 100%;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_opt_video{
    font-family: 'Bebas Neue';
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 0px 20px;
    color: #acb7c1;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_opt_video .opt_click{
    position: relative;
    cursor: pointer;
    padding-bottom: 2px;
    color: #555555;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_opt_video .opt_click:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 4px;
    background-color: #ffffff;
    scale: 0 1;
    transform-origin: left;
    transition: scale 0.36s;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_opt_video .opt_click:hover::before{
    scale: 1;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_opt_video .click_active{
    position: relative;
    cursor: default;
    border-bottom: 4px solid #ffffff;
    color: #ffffff;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video{
    margin-bottom: 20px;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .text{
    padding: 0px 20px;
    margin: 20px 0px;
    font-size: 16px;
    font-family: 'Roboto';
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .text a{
    color: #2ba2e2;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .text a:hover{
    text-decoration: underline;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .download{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin: 20px 20px 10px;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .download .data{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #193B4D;
    color: #ffffff;
    padding-right: 10px;
    cursor: pointer;
    width: 200px;
    max-width: 200px;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .download .data .img{
    width: 40px;
    height: 40px;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .download .data .img .img_{    
    width: 100%;
    height: 40px;
}
.Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .download .data .download_title{
    font-size: 14px; 
}
.Model_CourseDashDetails .div_data_course .inf_course .div_text{
    padding: 0px 20px;
    margin-bottom: 20px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_text .text{
    font-family: 'inter';
    font-size: 16px;
    color: #ffffff;
}
/* end */


/* div conclusion */
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #D2D2D2;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .img{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: relative;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .img .img_{
    width: 300px;
    height: 100%;  
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .img .div_marking{
    background-color: #2cb742;
    bottom: 4px;
    color: #fff;
    padding: 4px 14px;
    position: absolute;
    right: -8px;
    letter-spacing: 1px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .img .div_marking .marking_triangle{
    background: linear-gradient(45deg,#136320,#136320 50%,transparent 51%,transparent);
    height: 8px;
    position: absolute;
    right: 0;
    top: -8px;
    width: 8px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion{
    flex-grow: 1;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion .name_course{
    font-family: 'Bebas Neue';
    font-size: 32px;
    line-height: 30px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .border_top{
    width: 100%;
    height: 2px;
    background-color: #3b3b3b;
    margin-bottom: 14px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text{
    flex-grow: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    width: 100%;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .div_final{
    height: 100%;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .a_discord{
    color: #2ba2e2;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .conclusion_title{
    font-family: 'Bebas Neue';
    font-size: 32px;
    line-height: 30px;
    color: #ffffff;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .input_text{
    width: calc(100% - 20px);
    padding: 10px;
    resize: none;
    min-height: 50px;
    
    outline: none;
    color: #ffffff;
    border: 1px solid #3b3b3b;
    background-color: #061821;
    font-family: 'inter';
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .list_opt_click{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .list_opt_click .div_opt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 130px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .list_opt_click .div_opt .div_opt_number{
    font-size: 12px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .list_opt_click .div_opt .div_opt_input .input_radio{
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .list_opt_click .div_opt .div_opt_title{
    font-size: 14px;
    text-align: center;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .list_opt_click .div_opt .div_opt_subtitle{
    font-size: 10px;
    color: #758597;
    text-align: center;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .conclusion_text .title_final{
    font-family: 'Bebas Neue';
    font-size: 56px;
    line-height: 50px;
    color: #ffffff;
    width: 100%;
    text-align: center;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .div_save{
    width: 100%;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_conclusion .div_save .div_save_name{
    border: none;
    background-color: #2ba2e2;
    color: #ffffff;
    width: 100%;
    height: 36px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
}
/* end */


/* upload */
.Model_CourseDashDetails .div_data_course .inf_course .div_upload{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .div_border{
    border: 2px dashed #346781;
    width: 96%;
    padding: 16px 0px;
    cursor: pointer;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .div_border .div_upload_icons{
    text-align: center;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .div_border .div_upload_icons .icons_upload{
    width: 70px;
    height: auto;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .div_border .div_upload_text{
    font-family: 'inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .div_border .div_upload_text .highlight{
    color: #18ADFF;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .div_border .upload_file{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload{
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    width: 100%;
    margin-top: 20px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 0px 14px;
    border-bottom: 2px solid #346781;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload:nth-last-child(-n+1){
    border-bottom: none;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload .show_date_file{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload .show_date_file .download_icons{
    display: flex;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload .show_name_file{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload .div_status{
    width: 14px;
    height: 14px;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload .status_1{
    background-color: #8cb409;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload .status_2{
    background-color: #ffc000;
}
.Model_CourseDashDetails .div_data_course .inf_course .div_upload .list_file_upload .show_files_upload .status_3{
    background-color: #d50000;
}
/* end */


/* module */
.Model_CourseDashDetails .div_data_course .list_module{
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    overflow-y: auto;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module{
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color:#061821;
    position: relative;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .show_modules{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    transition: 0.5s;
    padding: 14px 10px;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .questions{
    justify-content: center;
    background-color: #2ba2e2;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .response{
    background-color: #2cb742;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_btn{
    color: #ffffff;
    background-color: #2cb742;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_btn:hover{
    background-color: #97b203;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .show_modules .icon_arrow{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .show_modules .icon_arrow .arrow_rotate{
    transform: rotate(-90deg);
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .show_modules .name_modules{
    color: #ffffff;
    font-family: 'inter';
    font-size: 14px;
    line-height: 16px;
    font-weight: 100;
    width: -webkit-fill-available;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .show_modules .qtd_classes{
    width: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 1;
    font-family: 'inter';
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes{
    width: calc(100% - 16px);

    max-height: 0;
    overflow-y: hidden;
    font-size: 14px;
    color: #3c3c3c;

    transition: max-height .2s ease-out;
    background-color: #061821;

    z-index: 8;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes .list_classes{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: #193B4D;
    padding: 10px;
    cursor: default;
    color: #ffffff;
    width: calc(100% - 20px);
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes .list_classes:hover{
    background-color: #324d6b;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes .classes_active{
    background-color: #2ba2e2;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes .list_classes .list_classes_input{
    display: flex;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes .list_classes .list_classes_input .input_checkbox{
    width: 18px;
    height: 18px;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes .list_classes .list_classes_title{
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 1.2px;
    cursor: pointer;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes .list_classes .list_classes_access{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Model_CourseDashDetails .div_data_course .list_module .div_module .div_list_classes .list_classes .list_classes_access .free {
    background-color: #5D9200;
    color: #ffffff;
    padding: 2px 10px;
    margin-right: 10px;
    cursor: default;
}
/* end */


@media only screen and (max-width: 910px) {
    .Model_CourseDashDetails .div_data_course{
        flex-direction: column;
    }
    .Model_CourseDashDetails .div_data_course .list_module{
        width: 100%;
        min-width: 100%;
    }
    .Model_CourseDashDetails .div_title_course .list_opt_topic .div_title{
        font-size: 26px;
        line-height: 24px;
    }
    .Model_CourseDashDetails .div_data_course .inf_course{
        width: 100%;
    }
}
@media only screen and (max-width: 580px) {
    .Model_CourseDashDetails .div_data_course .inf_course .dash_opt_video .opt_click,
    .Model_CourseDashDetails .div_data_course .inf_course .dash_opt_video .click_active{
        font-size: 14px;
    }
    .Model_CourseDashDetails .div_data_course .inf_course .dash_show_click_opt_video .text{
        font-size: 12px;
    }
    .Model_CourseDashDetails .div_title_course .list_opt_topic .div_title{
        font-size: 18px;
        line-height: 16px;
    }
}