.div_signature{
    color: #ffffff;
    position: absolute;
    z-index: 10;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.sig_left{
    left: 10px;
    align-items: flex-start;
}
.sig_right{
    right: 10px;
    align-items: flex-end;
}
.div_signature .sig_origin{
    opacity: 0.26;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 18px;
    text-shadow: 2px 2px 8px #000;
}
.div_signature .sig_name{
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 18px;
    line-height: 16px;
    text-shadow: 2px 2px 8px #000;
}