.Model_ModulesWorkload{
    width: 100%;
    height: auto;
    padding-top: 80px;
    overflow: hidden;
}
.Model_ModulesWorkload .container{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
    transition: 0.5s;
}

.Model_ModulesWorkload .bg_img{
    width: 50%;
    height: -webkit-fill-available;
    flex-grow: 1;
    position: relative;
}
.Model_ModulesWorkload .bg_img .img{
    width: 100%;
    display: flex;
}
.Model_ModulesWorkload .div_text{
    width: 50%;
    font-family: 'Bebas Neue', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    padding-bottom: 80px;
    flex-grow: 1;
}
.Model_ModulesWorkload .div_text .title{
    font-size: 50px;
    color: #FFAD00;
    padding-left: 5px;
    line-height: 60px;
}
.Model_ModulesWorkload .div_text .table {
    font-size: 14px;
    border-spacing: 0px;
    font-family: 'Inter';
    text-transform: capitalize;
    font-weight: 700;
    color: #ffffffb3;
    width: 100%;
}
.Model_ModulesWorkload .div_text .table table{
    width: 100%;
    padding-bottom: 0;
}
.Model_ModulesWorkload .div_text .table table tr{
    background: transparent;
    box-shadow: none;
}
.Model_ModulesWorkload .div_text .table .table_numb{
    text-align: center;
    color: #0A2541;
    width: 30px;
    height: 30px;
}
.Model_ModulesWorkload .div_text .table .table_text{
    background-color: #103860;
    padding: 10px;
}
.Model_ModulesWorkload .div_text .table .table_time{
    width: 60px;
    text-align: center;
    color: #0A2541;
}
.Model_ModulesWorkload .div_text .time{
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Inter';
    color: #ffffff;
    font-size: 12px;   
}

@media only screen and (max-width: 910px) {
    .Model_ModulesWorkload .container{
        flex-direction: column;
    }
    .Model_ModulesWorkload .div_text{
        order: 1;
        width: 100%;
        align-items: center;
        padding-bottom: 0px
    }
    .Model_ModulesWorkload .bg_img{
        order: 2;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Model_ModulesWorkload .bg_img .img{
        width: 50%;
    }
}
@media only screen and (max-width: 580px) {
    .Model_ModulesWorkload .div_text .title{
        font-size: 40px;
        line-height: 38px;
    }
    .Model_ModulesWorkload .bg_img .img{
        width: 80%;
    }
}