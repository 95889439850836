.Model_CourseDashStarted{
    width: 100%;
    height: auto;
    padding: 40px 0px 0px;
    position: relative;
    overflow: hidden;
}
.Model_CourseDashStarted .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    transition: 0.5s;
}
.Model_CourseDashStarted .container .title{
    font-family: 'Bebas Neue';
    color: #ffffff;
    font-size: 24px;
    line-height: 20px;
}
.Model_CourseDashStarted .list_data_course{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}


.Model_CourseDashStarted .list_data_course .div_data_course{
    width: 280px;
    cursor: pointer;
    font-size: 12px;
    background-color: #061821;
    position: relative;
}
.Model_CourseDashStarted .list_data_course .div_data_course .close_course{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 70%);

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #fff;
    font-size: 16px;
}
.Model_CourseDashStarted .list_data_course .div_data_course .close_course .title{
}
.Model_CourseDashStarted .list_data_course .div_data_course .courses_img{
    width: 100%;
    height: auto;
    position: relative;
    transition: 0.5s;
}
.Model_CourseDashStarted .list_data_course .div_data_course .courses_img .img_{
    width: 100%;
    height: 100%;
    max-height: 157px;
}
.Model_CourseDashStarted .list_data_course .div_data_course .courses_img .div_marking{
    background-color: #2cb742;
    bottom: 4px;
    color: #fff;
    padding: 4px 14px;
    position: absolute;
    right: -8px;
    letter-spacing: 1px;
}
.Model_CourseDashStarted .list_data_course .div_data_course .courses_img .div_marking .div_triangle{
    background: linear-gradient(45deg,#136320,#136320 50%,transparent 51%,transparent);
    height: 8px;
    position: absolute;
    right: 0;
    top: -8px;
    width: 8px;
}
.Model_CourseDashStarted .list_data_course .div_data_course .div_text{
    color: #0083c7;
    height: 64px;
    max-height: 64px;
    min-height: 64px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: 0.5s;
}
.Model_CourseDashStarted .list_data_course .div_data_course .div_text .text{
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    transition: 0.5s;
    overflow: hidden;

    text-overflow: ellipsis;
    text-align: justify;

    height: 36px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media only screen and (max-width: 985px) {
}
@media only screen and (max-width: 680px) {    
    .Model_CourseDashStarted .list_data_course .div_data_course{
        flex-grow: 1;
    }
}
@media only screen and (max-width: 634px) {
    .Model_CourseDashStarted .list_data_course .div_data_course{
        max-width: fit-content;
    }
}