.PopUp .ShowFile {
    width: 760px;
}
.PopUp .ShowFile .title_file{
    justify-content: right;
}
.PopUp .ShowFile .show_img{
    max-width: 100%;
}
.PopUp .ShowFile .aspect_video{
    aspect-ratio: 16/9;
    width: -webkit-fill-available;
}
.PopUp .ShowFile .div_video{
    width: -webkit-fill-available;
}