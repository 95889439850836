.PopUp .CheckedCourse{
    width: 650px;
}
.PopUp .CheckedCourse .pop_title {
    font-size: 28px;
    font-family: 'Bebas Neue';
    line-height: normal;
    color: #ffffff;
}
.PopUp .CheckedCourse .pop_text {
    font-size: 14px;
    text-align: center;
    width: 80%;
    margin: 0% auto;
    color: #ffffff;
}
.PopUp .CheckedCourse .pop_video {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.PopUp .CheckedCourse .div_btn{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.PopUp .CheckedCourse .pop_div_button{
    font-size: 18px;
    /* font-family: 'Bebas Neue'; */
    line-height: normal;
    background-color: #5D9200;
    color: #ffffff;
    width: -webkit-fill-available;
    padding: 10px;
    margin-top: 20px;
}
.PopUp .CheckedCourse .details_course{
    background-color: #fb5b00;
}

@media only screen and (max-width: 890px) {
    .PopUp .CheckedCourse{
        width: 90%;
    }
}